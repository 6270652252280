import $, { extend } from 'jquery';
import React from 'react';
import axios from 'axios';
import {UserToken,getUserObjData} from '../function_lib/userToken';
import {CallMapMethod,AjaxCallMethod,CallMapMethod_CBack,validateNumberLength,TableDatePicker} from '../function_lib/common_lib';

import {Custom_Alert,Alert_Content} from '../components/custom_alert';

import Header from './header';
import Footer from './footer';


import defaultPic from '../assets/images/9.png';

import Comp_Edit_Patient from '../components/comp_edit_patient';


let loginUser="";
let userObject="";
let companyName="";
let clsOpdReports;

let rf="0";
//let genderList=["M.","F."];
let genderList=["Male","Female","Boy","Girl"];
class Opd_Reports extends React.Component{
  forceUpdateHandler(){
    this.forceUpdate();
  };

    constructor(props){
        super(props);
        if(localStorage.getItem("userData")!=null){
          userObject= getUserObjData();
          //alert(JSON.stringify(userObject));
          loginUser=userObject[0].first_name;
          companyName=userObject[0].first_name;
      }
      this.state={ listUsers:[],crediUser:{},creditBalance:[],masterBalance:[],
      stDoctorsList:[],stDepartmentList:[],stDiagnosisList:[],
      stReceiptAmount:[],stEditPatient:[],stPicImage:"",stPresImage:""};
          this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
          clsOpdReports=this;
      }

      PrintOpdReceipt(item){
        //alert(JSON.stringify(item));
        let amount=0;
        amount=parseFloat(item["receipt_amount"]);
        let strAmount="Free";
        if(amount>0){
          strAmount="Paid";
        }
        let myobj={"opd_no":item["opd_no"],"date":item["fmt_reg_date"],"dr_department":'',"rog_nidan":'',
      "pt_name":item["name"],"age":item["age"],"gender":item["gender"],"address":item["address"],"amount":strAmount};

        // let myobj = {'opd_no': opdNo,'date':request.form["req_date"],'dr_department':'','rog_nidan':'',
        //      'pt_name':request.form["patient_name"],'age':request.form["age_year"]+"Y/"+request.form["age_month"]+"M/"+request.form["age_days"]+"D",
        //      'gender':request.form["gender"],'address':request.form["address"],'amount':arrRecptAmt[1]}

        axios.post('http://localhost:1010/print_inv', myobj)
      .then(response => {
        alert("Print Successfully");
        //console.log('Response:', response.data);
        // Handle success, if needed
      })
      .catch(error => {
        alert("Unable to Print"+error);
        //console.error('Error:', error);
        // Handle error, if needed
      });
      }

      getPatientData(isSearch=false){
        //child component use this method loan_document_upload_comp
        var map=[];
        map.push({name:"curl_type",value:"getPatientData"});
        map.push({name:"res_func",value:"callBackrrr"});
        map.push({name:"local_token",value:userObject[0].app_user_id});
        map.push({name:"user_type",value:userObject[0].type});
        map.push({name:"upper_id",value:userObject[0].upper_id});

        /* search parameters */
        if(isSearch===true){
          map.push({name:"ser_text",value:$("#ser_text").val()});
          map.push({name:"ser_gender",value:$("#ser_gender").val()});
          map.push({name:"ser_doctor_id",value:$("#ser_doctor_id").val()});
          map.push({name:"ser_diagnosis_id",value:$("#ser_diagnosis_id").val()});
          map.push({name:"ser_section_id",value:$("#ser_section_id").val()});
          map.push({name:"ser_receipt_amount_id",value:$("#ser_receipt_amount_id").val()});
          map.push({name:"ser_from_date",value:$("#ser_from_date").val()});
          map.push({name:"ser_to_date",value:$("#ser_to_date").val()});
        }

        map["res_func"]="callBackrrr";
        map["curl_type"]="getPatientData";
        CallMapMethod_CBack(clsOpdReports,map,function(data,msg){
          //alert(data)
           if(data!=="0" && data!==""){
              data=JSON.parse(data);
                let listData=data;
                var strData="";
                
                clsOpdReports.setState({listUsers:listData},()=>{
                  //window.getPagination("#tbData");
                  clsOpdReports.forceUpdateHandler();
                  //RespBkMthdMapp(clsUserMgmt,"_btnShareOption","");
                 
                  if(rf!=="0"){
                    clsOpdReports.getSearch(rf);
                    //alert("i m in setup "+rf);
                    $("#serLoanStatus").val(rf);
                  }
                });
              }
        });
      }
     
      getCategories(status="0"){
        //child component use this method loan_document_upload_comp
        var map=[];
        map.push({name:"curl_type",value:"getDepDocDia"});
        map.push({name:"res_func",value:"callBackrrr"});
        map.push({name:"local_token",value:userObject[0].app_user_id});
        map.push({name:"user_type",value:userObject[0].type});
        map.push({name:"ser_userType",value:$("#ser_userType").val()});
        map.push({name:"ser_text",value:$("#ser_text").val()});
        map.push({name:"get_vendor",value:"yes"});
        //alert(status);
        /*
        if(status!=="0"){
          map.push({name:"status",value:status});
        }
        not required as of now
        */
        map.push({name:"upper_id",value:userObject[0].upper_id});
        map["res_func"]="callBackrrr";
        map["curl_type"]="getDepDocDia";
        CallMapMethod_CBack(clsOpdReports,map,function(data,msg){
         // alert(data);
            if(data!=="0" && data!==""){
                let listData=JSON.parse(data);
                var strData="";
               // alert(JSON.stringify(listData.receipt_amount));
               clsOpdReports.setState({stDoctorsList:listData.doctors,
                  stDepartmentList:listData.department,stDiagnosisList:listData.diagnosis,
                  stReceiptAmount:listData.receipt_amount},()=>{
                
                //  clsWappCamp.forceUpdateHandler();
                  //RespBkMthdMapp(clsUserMgmt,"_btnShareOption","");
                });
              }
        });
      }

onEditOpdPatient(item){

  clsOpdReports.setState({stEditPatient:[item]},()=>{
    AjaxCallMethod(this,"okSuccCNOT");
  })
}

onGetImageSer(item){
var map=[];
        map.push({name:"curl_type",value:"getImage"});
        map.push({name:"res_func",value:"callBackrrr"});
        map.push({name:"local_token",value:userObject[0].app_user_id});
        map.push({name:"user_type",value:userObject[0].type});
        map.push({name:"upper_id",value:userObject[0].upper_id});
        if(item.pt_pic!==null && item.pt_pic!==""){
          
          map.push({name:"pt_pic",value:item.pt_pic});
        }
        if(item.pt_pres!==null && item.pt_pres!==""){
          map.push({name:"pt_pres",value:item.pt_pres});
        }
        map["res_func"]="callBackrrr";
        map["curl_type"]="getImage";
        CallMapMethod_CBack(clsOpdReports,map,function(data,msg){
          //alert(data);
          if(data!=="0"){
            let listData=JSON.parse(data);
            clsOpdReports.setState({stPicImage:listData.pic_img,stPresImage:listData.pic_pres});
          }else{
            clsOpdReports.setState({stPicImage:"",stPresImage:""});
          }
        })
 
}
  
      
componentDidMount(){
    this.getCategories();
 
$("#app_user_id").val(userObject[0].app_user_id);
this.getPatientData(true);

}
okSuccEditPt(data,msg){
  alert(""+data+msg);
}


      render(){
          return(<div>
<Header/>
<div id="repTest"></div>
<div className="content">
<div className="container-fluid mt-4">
<div className="row content12 mt-2 mb-2">

<div className='col-lg-2 col-md-2 col-sm-4 float-right'>
<div className="form-group">
         <div className="input-group">
               <select id="ser_receipt_amount_id" name="ser_receipt_amount_id" className="form-control form-control-sm">
                  <option value={"0"}>-Payment-</option>
                  {this.state.stReceiptAmount.map((item,index)=>{
                                                    return <option value={item["id"]}>{item["name"]}</option>
                                                  })}
               </select>
             </div>
         
         </div>

</div>

<div className='col-lg-2 col-md-2 col-sm-4 float-right'>
<div className="form-group">
         <div className="input-group">
               <select id="ser_section_id" name="ser_section_id" className="form-control form-control-sm">
                  <option value={"0"}>-Department-</option>
                  {this.state.stDepartmentList.map((item,index)=>{
                                                    return <option value={item["id"]}>{item["name"]}</option>
                                                  })}
               </select>
             </div>
         
         </div>

</div>
<div className='col-lg-2 col-md-2 col-sm-4 float-right'>
<div className="form-group">
         <div className="input-group">
               <select id="ser_diagnosis_id" name="ser_diagnosis_id" className="form-control form-control-sm">
                  <option value={"0"}>-Diagnosis-</option>
                  {this.state.stDiagnosisList.map((item,index)=>{
                                                    return <option value={item["id"]}>{item["name"]}</option>
                                                  })}
               </select>
             </div>
         
         </div>

</div>
<div className='col-lg-2 col-md-2 col-sm-4 float-right'>
<div className="form-group">
         <div className="input-group">
               <select id="ser_doctor_id" name="ser_doctor_id" className="form-control form-control-sm">
                  <option value={"0"}>-Doctor-</option>
                  {this.state.stDoctorsList.map((item,index)=>{
                                                    return <option value={item["id"]}>{item["name"]}</option>
                                                  })}
               </select>
             </div>
         
         </div>

</div>
<div className='col-lg-2 col-md-2 col-sm-4 float-right'>
<div className="form-group">
         <div className="input-group">
               <select id="ser_gender" name="ser_gender" className="form-control form-control-sm">
                  <option value={"0"}>-Gender-</option>
                  {genderList.map((item,index)=>{
                                                    return <option value={item}>{item}</option>
                                                  })}
               </select>
             </div>
         
         </div>

</div>


<div className='col-lg-2 col-md-2 col-sm-4 float-right'>
<div className="form-group">
         <div className="input-group">
               <input autoComplete='off' type="text" id="ser_text" name="ser_text" className="_setSearchKeyUp form-control form-control-sm" placeholder='OPD No' />
             </div>
         
         </div>

</div>
<div className='col-lg-4 col-md-4 col-sm-4 float-right'>
<div className="form-group">
         <div className="input-group">
         <TableDatePicker refName="ser_from_date" />-To-
         <TableDatePicker refName="ser_to_date"/>
               {/* <input autoComplete='off' type="text" id="ser_from_date" name="ser_from_date" className="form-control form-control-sm" data-inputmask-alias="datetime" data-inputmask-inputformat="dd/MM/yyyy" data-mask="true" placeholder='dd/MM/yyyy' />-TO- */}
               {/* <input autoComplete='off' type="text" id="ser_to_date" name="ser_to_date" className="form-control form-control-sm" data-inputmask-alias="datetime" data-inputmask-inputformat="dd/MM/yyyy" data-mask="true" placeholder='dd/MM/yyyy' /> */}
             </div>
         
         </div>

</div>

<div className="col-lg-2 col-md-2 col-sm-4 vcenter-item1 float-right">
<div className="input-group">
               
               <div className="input-group-prepend">
               <div className='col-12 '>
                 <div onClick={()=>this.getPatientData(true)}  style={{marginRight:"10px"}} className="btn btn-sm btn-danger">Search</div>
                 <div  className="btn btn-sm btn-danger">Reset</div>
                 </div>
               </div>
             </div>

</div>
    </div>    
<div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
         
      <div class="card">
              {/* <!-- /.card-header --> */}
           <div class="card-header bg-cyan">
                <h3 class="card-title "><i class="fa fa-arrow-circle-right"></i> <b id="listmsg">{"Total Records :"+this.state.listUsers.length}</b></h3>
                
                <a className="btn btn-sm btn-danger float-right" href="../patient_entry">Add New</a>
              </div>
                <table id="tbData" class="dtgrid dtgrid table table-bordered table-hover text-sm text-nowrap1 table-responsive">
                  <thead>
                    <tr class="border">
                      <th>S.No.</th>
                      <th>Opd No</th>
                      <th>Ipd No</th>
                      <th>Free/Paid</th>
                      <th>Pt. Name</th>
                      <th>Contact No.</th>
                      <th>Age</th>
                      <th>Gender</th>
                      <th>Address</th>
                      <th>Ref Doctor</th>
                      <th>Diagnosis</th>
                      <th>Department</th>
                      <th>Images</th>
                      <th>Date</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                  {(this.state.listUsers.map((item,index)=>{
                       return(<tr>
                  <td>{item.sno}</td>
                  <td>{item.opd_no}</td>
                  <td>{item.ipd_no}</td>
                  <td>{item.receipt_amount}</td>
                  <td>{item.name}</td>
                  <td>{item.contact_no}</td>
                  <td>{item.age}</td>
                  <td>{item.gender}</td>
                  <td>{item.address}</td>
                  <td>{item.doctor_name}</td>
                  <td>{item.diagnosis_name}</td>
                  <td>{item.section_name}</td>
                  <td><a  data-toggle="modal" data-target="#divViewImage" onClick={()=>this.onGetImageSer(item)}  href="javascript:void(0)">View Image</a></td>
                  <td>{item.fmt_reg_date}</td>
                  <td><a  data-toggle="modal11" data-target="#divWaCreditBalance11" onClick={()=>this.PrintOpdReceipt(item)}  href="javascript:void(0)">Print</a> |<a  data-toggle="modal" onClick={()=>this.onEditOpdPatient(item)} data-target="#divWaEditPatient"  href="javascript:void(0)">Edit</a></td>
                    </tr>)
                  }))}
                  </tbody>
                </table>
                {/* <div class='pagination-container' >
				<nav>
				  <ul class="pagination">
            
            <li data-page="prev" >
								     <span> {"<"} <span class="sr-only">(window.current)</span></span>
								    </li>
				  
        <li data-page="next" id="prev">
								       <span> {">"} <span class="sr-only">(window.current)</span></span>
								    </li>
				  </ul>
				</nav>
			</div> */}
              {/* <!-- /.card-body --> */}
            </div>
      </div>
</div>
</div>
</div>
<Alert_Content/>
<Footer/>
<div className="modal fade bd-example-modal-xl"  id="divViewImage" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-xl" role="document">
    <div className="modal-content">
    <div className="modal-header bg-primary">
        <h5 className="modal-title text-white" id="exampleModalLabel">Add New Category</h5>
        <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" className="text-white">&times;</span>
        </button>
      </div>
      <form id="frmViewImage" name="frmViewImage">
     
      <div className="modal-body">
      <div className="row">
                {/* <!-- left column --> */}
                <div className="col-md-12">
                    <div className="card card-danger">
                            <input type="hidden" name="res_func" id="res_func" value="okSucc" />
                            <input type="hidden" name="curl_type" id="curl_type" value="addNewCategory" />
                            <input type="hidden" name="m_type" id="m_type" value="addNewCategory" />
                            <input type="hidden" name="type_id" id="type_id" value="2" />
                            <input type="hidden" name="upper_id" id="upper_id" value={userObject[0].app_user_id} />
                            <div className="card-body">
                              {this.state.stPicImage!==""?<img height={"100px"} src={"data:image/png;base64,"+this.state.stPicImage} />:''}

                              {this.state.stPresImage!==""?<img width={"100%"} src={"data:image/png;base64,"+this.state.stPresImage} />:''}
                                {/* {this.state.stImageName!==""?} */}
                                <div className="col-md-6 respSSL"></div>
                                <div id="testing2dd2222"></div>
                            </div>
                        
                    </div>
                </div>
            </div>
      </div>
      <div className="modal-footer">
      <div id="getReponse" style={{color:"red"}}></div>
        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" className="btn _btnSetColor btn-primary">Add New Category</button>
      </div>
      </form>
    </div>
    </div>
    </div>
    <Comp_Edit_Patient 
    listDoctors={this.state.stDoctorsList}
    listDepartments={this.state.stDepartmentList}
    listDiagnosis={this.state.stDiagnosisList}
    listReceiptAmount={this.state.stReceiptAmount}
    genderList={genderList} app_user_id={userObject[0].app_user_id} 
    pEditPatient={this.state.stEditPatient}
     
     />
          </div>)
      }
}
export default Opd_Reports;
import $, { extend } from 'jquery';
import React from 'react';
import axios from 'axios';
import {UserToken,getUserObjData} from '../function_lib/userToken';
import {CallMapMethod,AjaxCallMethod,GetImgCertUser_CBack,RespBkMthdMapp,detectEmojisAndSpecialCharacters,
  CallMapMethod_CBack,btnSetColor_CBack,FormatDate,GetCurrentDtTime,TableDatePicker,GetImgCert_CBack} from '../function_lib/common_lib';
//import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';

import {Custom_Alert,Alert_Content} from '../components/custom_alert';
import Header from '../user_pages/header';
import Footer from '../user_pages/footer';



import defaultPic from '../assets/images/9.png';
import pdfIcon from '../assets/images/pdf_icon.png';
import mp3Icon from '../assets/images/mp3_icon.png';
import mp4Icon from '../assets/images/mp4_icon.png';

let loginUser="";
let userObject="";
let companyName="";
let clsWappCamp;


let totalFileAllow=5;

let objImages = [];
let indexExisting=0;
let arrExistingFiles=[];


let objVideo=[];
let vidIndexExisting=0;
let arrVidExistingFiles=[];

let objPdf=[];
let pdfIndexExisting=0;
let arrPdfExistingFiles=[];

//let genderList=["पु.","म.","बा.","बालि."];
let genderList=["Male","Female","Boy","Girl"];
class Patient_Entry extends React.Component{
  forceUpdateHandler(){
    this.forceUpdate();
  };

    constructor(props){
        super(props);
        if(localStorage.getItem("userData")!=null){
          userObject= getUserObjData();
          //alert(JSON.stringify(userObject));
          loginUser=userObject[0].first_name;
          companyName=userObject[0].first_name;
      }
      this.state={stDoctorsList:[],stDepartmentList:[],stDiagnosisList:[],
      stReceiptAmount:[],listUsers:[]};
      clsWappCamp=this;
    
      }
      getCategories(status="0"){
        //child component use this method loan_document_upload_comp
        var map=[];
        map.push({name:"curl_type",value:"getDepDocDia"});
        map.push({name:"res_func",value:"callBackrrr"});
        map.push({name:"local_token",value:userObject[0].app_user_id});
        map.push({name:"user_type",value:userObject[0].type});
        map.push({name:"ser_userType",value:$("#ser_userType").val()});
        map.push({name:"ser_text",value:$("#ser_text").val()});
        map.push({name:"get_vendor",value:"yes"});
        //alert(status);
        /*
        if(status!=="0"){
          map.push({name:"status",value:status});
        }
        not required as of now
        */
        map.push({name:"upper_id",value:userObject[0].upper_id});
        map["res_func"]="callBackrrr";
        map["curl_type"]="getDepDocDia";
        CallMapMethod_CBack(clsWappCamp,map,function(data,msg){
         // alert(data);
            if(data!=="0" && data!==""){
                let listData=JSON.parse(data);
                var strData="";
               // alert(JSON.stringify(listData.receipt_amount));
                clsWappCamp.setState({stDoctorsList:listData.doctors,
                  stDepartmentList:listData.department,stDiagnosisList:listData.diagnosis,
                  stReceiptAmount:listData.receipt_amount},()=>{
                
                //  clsWappCamp.forceUpdateHandler();
                  //RespBkMthdMapp(clsUserMgmt,"_btnShareOption","");
                });
              }
        });
      }




  //imgLibThumb


  PrintOpdReceipt(item){
    //alert(JSON.stringify(item));
    let amount=0;
    amount=parseFloat(item["receipt_amount"]);
    let strAmount="0";
    if(amount>0){
      strAmount="1";
    }
    let myobj={"opd_no":item["opd_no"],"date":item["fmt_reg_date"],"dr_department":'',"rog_nidan":'',
  "pt_name":item["name"],"age":item["age"],"gender":item["gender"],"address":item["address"],"amount":strAmount};

    // let myobj = {'opd_no': opdNo,'date':request.form["req_date"],'dr_department':'','rog_nidan':'',
    //      'pt_name':request.form["patient_name"],'age':request.form["age_year"]+"Y/"+request.form["age_month"]+"M/"+request.form["age_days"]+"D",
    //      'gender':request.form["gender"],'address':request.form["address"],'amount':arrRecptAmt[1]}

    axios.post('http://localhost:1956/print_inv', myobj)
  .then(response => {
    alert("Print Successfully");
    //console.log('Response:', response.data);
    // Handle success, if needed
  })
  .catch(error => {
    alert("Unable to Print"+error);
    //console.error('Error:', error);
    // Handle error, if needed
  });
  }

  PrintOpdReceiptQuick(item){
    //alert(JSON.stringify(item));
    let confirmOk=window.confirm("Record added successfully,Do print the receipt ?");
    if(confirmOk===true){
      let amount=0;
      amount=parseFloat(item["amount"]);
      let strAmount="0";
      if(amount>0){
        strAmount="1";
      }
      let myobj={"opd_no":item["opd_no"],"date":item["date"],"dr_department":'',"rog_nidan":'',
    "pt_name":item["pt_name"],"age":item["age"],"gender":item["gender"],"address":item["address"],"amount":strAmount};
  
      // let myobj = {'opd_no': opdNo,'date':request.form["req_date"],'dr_department':'','rog_nidan':'',
      //      'pt_name':request.form["patient_name"],'age':request.form["age_year"]+"Y/"+request.form["age_month"]+"M/"+request.form["age_days"]+"D",
      //      'gender':request.form["gender"],'address':request.form["address"],'amount':arrRecptAmt[1]}
  
      axios.post('http://localhost:1010/print_inv', myobj)
    .then(response => {
      alert("Print Successfully");
      window.location.reload();
      //console.log('Response:', response.data);
      // Handle success, if needed
    })
    .catch(error => {
      alert("Unable to Print"+error);
      window.location.reload();
      //console.error('Error:', error);
      // Handle error, if needed
    });
    
    }else{
      window.location.reload();
    }
    
  }





      getPatientData(isSearch=false){
        //child component use this method loan_document_upload_comp
        var map=[];
        map.push({name:"curl_type",value:"getPatientData"});
        map.push({name:"res_func",value:"callBackrrr"});
        map.push({name:"local_token",value:userObject[0].app_user_id});
        map.push({name:"user_type",value:userObject[0].type});
        map.push({name:"upper_id",value:userObject[0].upper_id});

        /* search parameters */
        if(isSearch===true){
          map.push({name:"ser_text",value:"0"});
          map.push({name:"ser_gender",value:"0"});
          map.push({name:"ser_doctor_id",value:"0"});
          map.push({name:"ser_diagnosis_id",value:"0"});
          map.push({name:"ser_section_id",value:"0"});
          map.push({name:"ser_receipt_amount_id",value:"0"});
          map.push({name:"ser_from_date",value:$("#ser_from_date").val()});
          map.push({name:"ser_to_date",value:$("#ser_to_date").val()});
        }

        map["res_func"]="callBackrrr";
        map["curl_type"]="getPatientData";
        CallMapMethod_CBack(clsWappCamp,map,function(data,msg){
          //alert(data)
           if(data!=="0" && data!==""){
              data=JSON.parse(data);
                let listData=data;
                var strData="";
                
                clsWappCamp.setState({listUsers:listData},()=>{
                  //window.getPagination("#tbData");
                  clsWappCamp.forceUpdateHandler();
                  //RespBkMthdMapp(clsUserMgmt,"_btnShareOption","");
                });
              }
        });
      }
     

saveWappCompaign(){
  //alert("Sending WAPP");
  //alert($("#contact_no").val());
/*
  let checkChar= $("#msg_text").val();
   let result=detectEmojisAndSpecialCharacters(checkChar);
  
if(result.emojis.length!==0 || result.specialCharacters.length!==0){
   alert("Special Characters and Emojis not allowd in text Message");
   return; 
}
*/
document.getElementById("btnSave").disabled=true;
$("#req_date").focus();

setTimeout(function() {
  // Once the action is complete, re-enable the button
  document.getElementById("btnSave").disabled=false;
  
}, 2000); // Replace this timeout with your actual action



GetImgCert_CBack(clsWappCamp,"#frmWappCamp","createPt",function(checkData,checkMsg){
//btnSetColor_CBack(clsWappCamp,"frmWappCamp","addProductMast",function(checkData,checkMsg){
  //alert(checkData+checkMsg)
  //alert(checkData);
  document.getElementById("btnSave").disabled=false;
  $("#patient_name").val("");
  if(checkData===-10){
    alert("Kindly fill required fields");
    return;
  }else if(checkData==="001"){
    alert("Added Successfully");
    // Custom_Alert("Successfully Added",checkMsg,true);
    
    //clsWappCamp.PrintOpdReceiptQuick(JSON.parse(checkMsg));
      window.location.reload();
  }else {
    alert("Not Added, Kindly check all records"+checkMsg);
    // Custom_Alert("Not added",checkMsg,false);
    //alert(checkMsg);
  }
  
});


  
// Custom_Alert("WAPP CAMPAIGN","<span>Test1</span><span>Test2</span>")
  
 
}

 isFloat(n) {
  let checkNum = false;
  if (Number(n)) {
      checkNum = true;
  } else if (n % 1 !== 0) {
      checkNum = true;
  } else {
      checkNum = false;
  }

  return checkNum;
}
 
componentDidMount(){
    
  this.getCategories();
  this.getPatientData(true);
  AjaxCallMethod(this,"okSuccCNOT");
$("#app_user_id").val(userObject[0].id);

//let getDate=GetCurrentDtTime();
let getDate=new Date();
const day = getDate.getDate().toString().padStart(2, '0');
const month = (getDate.getMonth() + 1).toString().padStart(2, '0'); // Note: Month is zero-based
const year = getDate.getFullYear();

// Format the date in dd/mm/yyyy format
const istFmtDate = `${day}/${month}/${year}`;

//let istFmtDate=FormatDate(getDate);

$("#req_date").val(istFmtDate);
$("#req_date").focus();

$(".cd_input_change").change(function () {
  let objPreCost = document.getElementById("pre_cost");
  let objOfferPer = document.getElementById("offer_per");
  let objFCost = document.getElementById("txt_cost");

    let valPreCost = 0;
    let valOfferPer = 0;
    let valFCost = 0;

    if (clsWappCamp.isFloat(objPreCost.value.trim())) {
      valPreCost = objPreCost.value.trim();
    }
    if (clsWappCamp.isFloat(objOfferPer.value.trim())) {
      valOfferPer = objOfferPer.value.trim();
    }
    if (clsWappCamp.isFloat(objFCost.value.trim())) {
      valFCost = objFCost.value.trim();
    }

    if(valOfferPer!==0){
      objFCost.value = valPreCost-(valPreCost * valOfferPer / 100).toFixed(2);
    }else{
      objFCost.value=objPreCost.value;
    }



})


// add event on entry
document.addEventListener('DOMContentLoaded', function() {
  var form = document.querySelector('form');
  form.addEventListener('keydown', function(e) {
    
    if (e.key === 'Enter') {
      if(e.target.id!=="btnSave"){
        e.preventDefault();
        var focusables = Array.from(form.elements).filter(el => !el.disabled && !el.hidden);
        var index = (focusables.indexOf(document.activeElement) + 1) % focusables.length;
        focusables[index].focus();
      }
      
    }
  });
});

}

   render(){
          return(<div>
<Header/>
<div className="content">
<div className="container-fluid mt-1">
<div className="row">
<div className="col-md-6 col-lg-6">
<section class="content">
  <div class="container-fluid">
    <div class="card card-secondary">
    <div class="card-header">
                                <h3 class="card-title">Today's Patient</h3>
                                
                            </div>      
                            <div class="card-body">
                            <TableDatePicker refName="ser_from_date" />-To-
         <TableDatePicker refName="ser_to_date"/>
         
                            <table id="tbData" class="dtgrid dtgrid table table-bordered table-hover text-sm text-nowrap1 table-responsive">
                  <thead>
                    <tr class="border">
                      <th>Opd No</th>
                      <th>Free/Paid</th>
                      <th>Pt. Name</th>
                      <th>Age</th>
                      <th>Date</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                  {(this.state.listUsers.map((item,index)=>{
                       return(<tr>
                  
                  <td>{item.opd_no}</td>
                  <td>{item.receipt_amount}</td>
                  <td>{item.name}</td>
                  <td>{item.age}</td>
                  <td>{item.fmt_reg_date}</td>
                  <td><a  data-toggle="modal11" data-target="#divWaCreditBalance11" onClick={()=>this.PrintOpdReceipt(item)}  href="javascript:void(0)">Print</a> |</td>
                    </tr>)
                  }))}
                  </tbody>
                </table>

                              </div>                        
    </div>
  </div>
</section>  
</div>
<div className="col-md-6 col-lg-6"> 
        
<section class="content">
<div class="container-fluid">
<div class="card card-secondary">
<div class="card-header">
                                <h3 class="card-title">Add New Patient</h3>
                                <a className="btn btn-sm btn-danger float-right" href="../opd_reports">View OPD Patient</a>
                            </div>
                            <form class="form-group" id="frmWappCamp" name="frmWappCamp" enctype="multipart/form-data" method="POST" onsubmit="event.preventDefault()">
                            <input type="hidden" name="res_func" id="res_func" value="okSucc" />
                            <input type="hidden" name="curl_type" id="curl_type" value="createPt" />
                            <input type="hidden" name="m_type" id="m_type" value="createPt" />
                            <input type="hidden" name="wa_msg_ty_ma_id" id="wa_msg_ty_ma_id" value={"1"} />
                            <input type="hidden" name="acc_inf_id" id="acc_inf_id" value={userObject[0].app_user_id}/>
                          
                            <input type="hidden" name="lsImages" id="lsImages" />
                            <input type="hidden" name="lsVideo" id="lsVideo"/>
                            <input type="hidden" name="lsPdf" id="lsPdf"/>
                            <div class="card-body">
                                <div className="row">
                                <div className="col-lg-12 col-md-12">
                                <div className="form-group form-group-sm row">
                                <label for="exampleInputEmail1" className="col-sm-3 col-md-3">Choose Patient Pic</label>
                                <div className="input-group input-group-sm col-sm-9 col-md-9">
                                  <input type="file" id="pt_pic" name="pt_pic" class="form-control" />
                                 </div> 
                                </div>  
                                </div>  
                                <div className="col-lg-12 col-md-12">
                                        <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1" className="col-sm-3 col-md-3">Date</label>
                                            <div className="input-group input-group-sm col-sm-9 col-md-9">
                                            {/* <TableDatePicker refName="req_date"/> */}
                                                <input type="text" id="req_date" name="req_date" data-inputmask-alias="datetime" data-inputmask-inputformat="dd/MM/yyyy" data-mask="true" className="form-control required" placeholder="" />
                                            </div>
                                        </div>
                                  </div>
                                
                                  {/* <div className="col-lg-3 col-md-3">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">S.No</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="sno" name="sno" className="form-control required" placeholder="" />
                                            </div>
                                        </div>
                                  </div> */}

                                  {/* <div className="col-lg-3 col-md-3">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">OPD No.</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="opd_no" name="opd_no" className="form-control required" placeholder="" />
                                            </div>
                                        </div>
                                  </div> */}
                                
                                </div>
                                <div class="row">
                                <div className="col-lg-12 col-md-12">
                                        <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1" className="col-sm-3 col-md-3 col-form-label">Patient Name</label>
                                            <div className="input-group input-group-sm col-sm-9 col-md-9">
                                                <input type="text" id="patient_name" name="patient_name" className="form-control required" placeholder="" />
                                            </div>
                                        </div>
                                  </div>
                                  <div className="col-lg-12 col-md-12">
                                        <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1" className="col-sm-3 col-md-3 col-form-label">Contact No.</label>
                                            <div className="input-group input-group-sm col-sm-9 col-md-9">
                                                <input type="text" id="contact_no" name="contact_no" className="form-control required" placeholder="" />
                                            </div>
                                        </div>
                                  </div>
                                <div className="col-lg-12 col-md-12">
                                        <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1" className="col-sm-3 col-md-3">Age</label>
                                            <div className="input-group input-group-sm col-sm-9 col-md-9">
                                                <input type="number" id="age_year" name="age_year" className="form-control required111" placeholder="Year" />/
                                                <input type="number" id="age_month" name="age_month" className="form-control required111" placeholder="Month" />/
                                                <input type="number" id="age_days" name="age_days" className="form-control required111" placeholder="Days" />
                                            </div>
                                        </div>
                                  </div>
                                  <div className="col-lg-12 col-md-12">
                                        <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1" className="col-sm-3 col-md-3">Gender</label>
                                            <div className="input-group input-group-sm col-sm-9 col-md-9">
                                            <select id="gender" name="gender" className="form-control required">
                                                  <option value="0">-Select Gender-</option>
                                                  {genderList.map((item,index)=>{
                                                    return <option value={item}>{item}</option>
                                                  })}
                                                </select>
                                            </div>
                                        </div>
                                  </div>
                                  <div className="col-lg-12 col-md-12">
                                        <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1" className="col-sm-3 col-md-3">Payee Of *</label>
                                            <div className="input-group input-group-sm col-ms-9 col-md-9">
                                                <select id="receipt_amount_id" name="receipt_amount_id" className="form-control required">
                                                  <option value="0">-Select-</option>
                                                  {this.state.stReceiptAmount.map((item,index)=>{
                                                    return <option value={item["id"]+","+item["amount"]}>{item["name"]}</option>
                                                  })}
                                                </select>
                                            </div>
                                        </div>
                                  </div>
 
                                  <div className="col-lg-12 col-md-12">
                                        <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1" className="col-sm-3 col-md-3">Address</label>
                                            <div className="input-group input-group-sm col-sm-9 col-md-9">
                                                <input type="text" id="address" name="address" className="form-control required11" placeholder="" />
                                            </div>
                                        </div>
                                  </div>
         
                                </div>  
                         <div className="row">
                          <div className="col-lg-12 col-md-12">
                                        <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1" className="col-sm-3 col-md-3">Doctor</label>
                                            <div className="input-group input-group-sm col-sm-9 col-md-9">
                                            <select id="doctor_id" name="doctor_id" className="form-control required11">
                                                  <option value={"0"}>-Doctor-</option>
                                                  {this.state.stDoctorsList.map((item,index)=>{
                                                    return <option value={item["id"]}>{item["name"]}</option>
                                                  })}

                                            </select>
                                            </div>
                                        </div>
                            </div>
                            <div className="col-lg-12 col-md-12">
                                        <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1" className="col-sm-3 col-md-3">Department</label>
                                            <div className="input-group input-group-sm col-sm-9 col-md-9">
                                            <select id="section_id" name="section_id" className="form-control required11">
                                                  <option value={"0"}>-Department-</option>
                                                  {this.state.stDepartmentList.map((item,index)=>{
                                                    return <option value={item["id"]}>{item["name"]}</option>
                                                  })}

                                            </select>
                                            </div>
                                        </div>
                                  </div>
                         <div className="col-lg-12 col-md-12">
                                        <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1" className="col-sm-3 col-md-3">Diagnosis</label>
                                            <div className="input-group input-group-sm col-sm-9 col-md-9">
                                                <select id="diagnosis_id" name="diagnosis_id" className="form-control required11">
                                                  <option value={"0"}>-Diagnosis-</option>
                                                  {this.state.stDiagnosisList.map((item,index)=>{
                                                    return <option value={item["id"]}>{item["name"]}</option>
                                                  })}

                                                </select>
                                            </div>
                                        </div>
                                  </div>
                                  
                              <div className="col-lg-12 col-md-12">
                                <div className="form-group form-group-sm row">
                                <label for="exampleInputEmail1" className="col-sm-3 col-md-3">Upload Prescription</label>
                                <div className="input-group input-group-sm col-sm-9 col-md-9">
                                  <input type="file" id="pt_presc" name="pt_presc" class="form-control" />
                                 </div> 
                                </div>  
                              </div>  
                                 
                            </div>       
                                <div className="col-md-6 respSSL"></div>
                                <div id="testing2dd2222"></div>
                                <div class="row">
                                    <div class="col-12">
                                      
                                        <div class="form-group">
                                        <center>
                                            <div class="input-group input-group-sm" style={{"justify-content":"center"}}>
                                                <button type="button" id="btnSave" name="btnSave" onClick={()=>this.saveWappCompaign()} class="btn btn-primary">Save</button>
                                            </div>
                                        </center>
                                        </div>
                                        
                                    </div>
                                </div>
                                </div>
                            </form>
    </div>
    </div>
</section>
      
</div>
    
</div>
</div>
</div>
<Alert_Content/>
<Footer/>

          </div>)
      }
}
export default Patient_Entry;
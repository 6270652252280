import $, { extend } from 'jquery';
import React from 'react';
import './custom_alert.css';

export const Alert_Content=()=>{
    return(<div class="coverbg" style={{"background-color":"rgba(212, 194, 194, 0.5)","width":"100%","height":"1000px","background-position":"center","background-size":"cover"}}>
    <div id="confirm">
       <div class="message alert_title"><b>{"Title"}</b>
           <span>
           <i class="icondesign fa fa-times" aria-hidden="true"></i>			       
        </span>
  
       </div><br></br>
          <div id="formdiv">
              <form method="post">
             <label for="fyear" class="flabel alert_body">{"Body"}</label> <br></br>            
              <input class="butn yes" onClick={()=>closeCusAlert()} type="button" name="send" id="submit" value="Ok" />
              </form>
          </div>
       </div>
  </div>);
 
}
export const closeCusAlert=()=>{
   var confirmBox = $("#confirm");
   // confirmBox.find(".yes").click();
   $('.coverbg').css('display','none');
   confirmBox.hide();
}
export const Custom_Alert=(title,body,isRefresh=true,redirectUrl="")=>{
  
    $(".coverbg").show();
    var confirmBox = $("#confirm");
    confirmBox.find(".alert_title").text(title);     
    confirmBox.find(".alert_body").html(body);
         
         confirmBox.find(".yes").unbind().click(function() {
            confirmBox.hide();
            if(isRefresh){
               if(redirectUrl===""){
                  window.location.reload();
               }else{
                  window.location.href=redirectUrl
               }
               

            }
            
         });
         //confirmBox.find(".yes").click();
         confirmBox.show();
}
export default Custom_Alert;